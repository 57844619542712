.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  /* background-image: url() */
  background-image: url("./assets/pexels-raul-juarez-2388639\ \(1\)\ \(1\).jpg");
  /* background-color: #cccccc; */
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.naming {
  text-transform: capitalize;
  letter-spacing: 5px;
  color: white;
  font-weight: 800;
  text-shadow: 1px 2px 2px rgb(3, 109, 104);
  font-size: 1.8rem;
}

h1 {
  color: rgb(3, 109, 104);
  font-size: 3rem;
  letter-spacing: 2px;

  margin-top: 200px;
  font-family: "Courier New", Courier, monospace;
  text-shadow: 1px 2px 2px black;
  background-color: white;
  opacity: 0.7;
  border-radius: 3px;
}

h2 {
  color: rgb(3, 109, 104);
  opacity: 0.7;
  border-radius: 3px;
  text-shadow: 1px 2px 2px black;
  font-family: "Courier New", Courier, monospace;
  text-transform: capitalize;
  letter-spacing: 10px;
  font-size: 2rem;
  margin-top: 60px;
  position: absolute;
  background-color: white;
}

.hero {
  /* background-color: white; */
  /* opacity: 0.76; */
  width: 50%;
  height: 15%;

  margin-top: 34%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (min-width: 300px) and (max-width: 950px) {
  .hero {
    margin-top: 350px;
  }
  h1 {
    font-size: 1rem;
  }

  h2 {
    font-size: 1rem;
  }
}
